import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion';

let datasheetsKeypad = [
  {
    "title": "Versa | Keypad | Vertical | Surface & Recess Mount | Mini",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/7tSFusPykZ2X3bYGKdrqLJ/f47bc27e8cd3f499eb34cbb6ca375bb5/DS-NMVM-K-DEN.pdf",
      "fileName": "DS-NMVM-K-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Keypad | Vertical | Surface & Recess Mount | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2dha5BLL77sCuYZpmOIM99/b34d2489a99691182676b593735887e1/DS-NMVS-K-DEN.pdf",
      "fileName": "DS-NMVS-K-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Keypad | Vertical | Surface & Recess Mount | Plus",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/5D6BWHcdC5dQ7RI849gjkU/d11ef4d80f73dd70014dbe0c54d2e7de/DS-NMVP-K-DEN.pdf",
      "fileName": "DS-NMVP-K-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Versa | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4tckHKHr44YCKdY0qTWBCZ/04766af7005ba78e76e2d6cbcc33a02e/IG-NMVM-DEN.pdf",
      "fileName": "IG-NMVM-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4ZdOt5GtxZktHnb9X061MN/febaaed37db039b769f267034a033523/IG-NMVS-DEN.pdf",
      "fileName": "IG-NMVS-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Plus",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7z8qGqPxQIG8MH5jlDAoYo/2cf76cc5447193e567bfa8354379770e/IG-NMVP-DEN.pdf",
      "fileName": "IG-NMVP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Versa | Shared & Assigned | Keypad | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5Q4RTjFL91b1fmPtVYCgcX/72c03e41b2a68e9b1a77689bbd5028fc/PG-NMVM-K-AD-DEN.pdf",
      "fileName": "PG-NMVM-K-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Shared & Assigned | Keypad Code Managed | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/6lGIJNbLhHpzh0wXGaFky4/5b25d709ef39208f2fea65ac528d35fc/PG-NMVM-C-AD-DEN.pdf",
      "fileName": "PG-NMVM-C-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Shared & Assigned | Keypad | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/771oMGYAZ5DJR2lngWo0WZ/9ca8774a56505d0c1a48e1deddc2f94f/PG-NMVS-K-AD-DEN.pdf",
      "fileName": "PG-NMVS-K-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Shared & Assigned | Keypad Code Managed | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/74tGJmgdDpieY7MH2raKrh/05b3abf84620ef3eb63d888549e1c2a3/PG-NMVS-C-AD-DEN.pdf",
      "fileName": "PG-NMVS-C-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Assigned | Keypad | Single User | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4WyRAKAQKKuPc9yyLO8sn0/e58458796363c222850bcbde631956dd/PG-NMVS-K-AS-DEN.pdf",
      "fileName": "PG-NMVS-K-AS-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Shared & Assigned | Keypad | Plus",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/2fqZf9VEjIkRNxlcQwzxT2/eb1c0e34dbd457e7660bbcab88e0e803/PG-NMVP-K-AD-DEN.pdf",
      "fileName": "PG-NMVP-K-AD-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

let datasheetsRFID = [
  {
    "title": "Versa | Touch RFID | Vertical | Surface & Recess Mount | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3rtSdoh9hHoow8SjntLYQ5/c2ac4e4f0596dee34d102b23a5b0fb28/DS-NMVM-R-DEN.pdf",
      "fileName": "DS-NMVM-R-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Touch RFID | Vertical | Surface & Recess Mount | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5p0SIzW5OwUU4Msedmzeko/3f25ce082754e3ccf88b5c88af1a1d26/DS-NMVS-R-DEN.pdf",
      "fileName": "DS-NMVS-R-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Touch RFID | Vertical | Surface & Recess Mount | Plus",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/3QRwtExIfkp8EifYygK16A/5e41278b5d53b23900c40597d206dc0c/DS-NMVP-R-DEN.pdf",
      "fileName": "DS-NMVP-R-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Versa | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4tckHKHr44YCKdY0qTWBCZ/04766af7005ba78e76e2d6cbcc33a02e/IG-NMVM-DEN.pdf",
      "fileName": "IG-NMVM-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4ZdOt5GtxZktHnb9X061MN/febaaed37db039b769f267034a033523/IG-NMVS-DEN.pdf",
      "fileName": "IG-NMVS-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Plus",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/7z8qGqPxQIG8MH5jlDAoYo/2cf76cc5447193e567bfa8354379770e/IG-NMVP-DEN.pdf",
      "fileName": "IG-NMVP-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Versa | Shared & Assigned | Touch RFID | Mini",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/ovduytBOL6Fh63N1nWJLV/3fd303602859aa93d6f6e3ef9b5b8dcd/PG-NMVM-R-DEN.pdf",
      "fileName": "PG-NMVM-R-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Shared & Assigned | Touch RFID | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2gq5Bu8G4H5hNpEO0jGAjP/836bf8fd6dacfb14a3b6ba1a582d78ab/PG-NMVS-R-DEN.pdf",
      "fileName": "PG-NMVS-R-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Versa | Assigned | Keypad | Single User | Standard",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/FUixH9yGWMkXqcItjER6q/65119cd1cec62fec440c525d0ed8c279/PG-NMVS-R-Single_Assigned_User-DEN.pdf",
      "fileName": "PG-NMVS-R-Single Assigned User-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  useEffect(() => {
    if (document.location.hash === '#rfid') {
      setToggleState(2);
    }
  }, [])

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Numeris Versa <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Numeris Versa <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'key.png'}
                  loading={'lazy'}
                  width={382}
                  height={522}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Versa Electronic Lock"
              />
            </div>
            <div className="lists">
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          datasheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'rfid.png'}
                  loading={'lazy'}
                  width={382}
                  height={522}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Numeris Versa Electronic Lock"
              />
            </div>
            <div className="lists">
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          datasheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;